
// Replaces higher level x-whatever container.
// Eventually this should be moved to a _layout once
// all pages are migrated and .page on the body has been moved from
// ethos.css
.sg-page {
  max-width: 1200px;
  width: 88%;
  margin: 120px auto 0; // breathing room from floating navbar

  > section {
    display: block;
    position: relative;
    margin: 0 0 2.15em;
    padding: 45px 0;

    // width: 88%;
    // margin: 0 auto;
  }
}
